<template>
  <div class="signInForm">
    <div class="box">
      <!-- Inner box -->
      <h1 class="title is-3">{{ title }}</h1>

      <authGoogle page="login" label="Continue in with Google TBD"></authGoogle>

      <button v-if="isSignIn" class="button is-primary">{{ buttonLabel }} with Email</button>

      <!--
    <button>{{ buttonLabel }} with Facebook</button>
    <button v-if="isSignIn">{{ buttonLabel }} with Apple</button>
    <button v-if="isSignIn">{{ buttonLabel }} with Twitter</button>
    <button>{{ buttonLabel }} with email</button>

     -->

      <p class="toggle" v-if="isSignIn">
        No account? <router-link to="/signup" class="actionLink">Create one</router-link>
      </p>
      <p class="toggle" v-else>
        Already have an account? <router-link to="/signin" class="actionLink">Sign in</router-link>
      </p>

      <p class="legalText" v-if="isSignIn">
        Forgot email or trouble signing in? <router-link to="/help">Get help.</router-link>
      </p>

      <p class="legalText">
        Click “{{ buttonLabel }}” to agree to OnlyBots’s <a href="/legal/terms" target="_blank">Terms of Service</a> and
        acknowledge that OnlyBots’s <a href="/legal/privacy" target="_blank">Privacy Policy</a> applies to you.
      </p>
    </div>
  </div>
</template>

<script>
import authGoogle from "@/components/auth/authGoogle.vue";

export default {
  props: {
    isSignIn: {
      type: Boolean,
      default: true,
    },
    mode: {
      type: String,
      default: "page", // can be 'page' or 'modal'
      validator(value) {
        return ["page", "modal"].includes(value);
      },
    },
  },
  components: {
    authGoogle,
  },
  data() {
    return {
      localAuthMode: this.isSignIn, // local state to decide sign in or sign up mode
    };
  },
  watch: {
    // If parent changes isSignIn, update the local state accordingly
    isSignIn(newVal) {
      this.localAuthMode = newVal;
    },
  },
  mounted() {
    if (this.mode === "modal") {
      document.body.classList.add("no-scroll");
    }
  },
  beforeDestroy() {
    if (this.mode === "modal") {
      document.body.classList.remove("no-scroll");
    }
  },
  computed: {
    title() {
      return this.localAuthMode ? "Welcome back." : "Join OnlyBots.";
    },
    buttonLabel() {
      return this.localAuthMode ? "Sign in" : "Sign up";
    },
    switchAuthModeLabel() {
      return this.localAuthMode ? "No account? Create one" : "Already have an account? Sign in";
    },
  },

  methods: {
    switchAuthMode() {
      if (this.mode === "modal") {
        this.localAuthMode = !this.localAuthMode;
      }
    },
  },
};
</script>

<style scoped>
.no-scroll {
  overflow: hidden !important;
}

.signInForm {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center; /* Horizontally center the box */
  align-items: center; /* Vertically center the box */
  background: rgba(255, 255, 255, 0.7); /* Dark overlay, adjust as needed */
  backdrop-filter: blur(10px);
  z-index: 9999;
}

.box {
  width: 100%;
  max-width: 600px;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  background: white;
  text-align: center;
}

/* Mobile Styles */
@media screen and (max-width: 768px) {
  .signInForm .box {
    margin: 0;
    padding: 15px;
    border-radius: 0;
    box-shadow: none;
  }
}
.signInForm >>> iframe {
  /* the button google generates is an iframe */
  margin: auto !important;
}

h1.title {
  margin-top: 1em;
  margin-bottom: 2em;
}

.signInForm button {
  margin-bottom: 10px;
}

.signInForm a,
.signInForm router-link {
  text-decoration: underline;
  margin: 5px 0;
}

.toggle {
  margin-top: 20px;
  margin-bottom: 20px;
}
.actionLink {
  text-decoration: underline;
  font-weight: bold;
  color: green;
  cursor: pointer;
}

.legalText {
  margin-top: 40px; /* Big margin on top */
  font-size: 0.8rem; /* Small font size */
  opacity: 0.5; /* Half opacity */
}

/* Add your CSS styles here */
</style>
