<template>
  <div class="containerNO">
    <!-- 
  v-if="!$store.main.getters.isLoggedIn"
  
        Snipet generator:
        https://developers.google.com/identity/gsi/web/tools/configurator
        
      -->
    <div
      id="g_id_onload"
      :data-client_id="GOOGLE_CLIENT_ID"
      data-context="signin"
      data-ux_mode="popup"
      data-callback="onGoogleGsiResponse"
      data-auto_select="true"
      data-itp_support="true"
    ></div>

    <!--  THE BTN    -->
    <div
      class="g_id_signin"
      data-type="standard"
      data-shape="rectangular"
      data-theme="outline"
      data-text="continue_with"
      data-size="large"
      data-logo_alignment="left"
    ></div>

    <!-- --  
      <b-button
        @click="loginGoogle"
        icon-left="google"
        icon-pack="fab"
        :size="size || 'is-large'"
        type="is-info"
        class="rounder"
        style="background:#4285f4"
        >&nbsp; &nbsp; Continue with Google &nbsp;
      </b-button>
      <br /><br />
  
      <b-button
        @click="loginGoogleLEGACY"
        icon-left="google"
        icon-pack="fab"
        :size="size || 'is-large'"
        type="is-info"
        class="rounder"
        style="background:#4285f4"
        >&nbsp; &nbsp;loginGoogleLEGACY &nbsp;
      </b-button>
  
  
  
       -->

    <!--
   TODO: move to a separate te,plate...
  -->

    <div class="row" v-if="showLoginPass">
      <div class="col-lg-6 offset-lg-3 col-sm-10 offset-sm-1">
        <form
          class="text-center border border-primary p-5"
          style="margin-top: 70px; height: auto; padding-top: 100px !important"
          @submit.prevent="loginUserPass"
        >
          <input type="text" id="email" class="form-control mb-5" placeholder="Email" v-model="login.email" />
          <!-- Password -->
          <input
            type="password"
            id="password"
            class="form-control mb-5"
            placeholder="Password"
            v-model="login.password"
          />
          <p>
            Dont have an account? Click
            <router-link to="/register"> here </router-link> to sign up
          </p>
          <!-- Sign in button -->
          <center>
            <button class="btn btn-primary btn-block w-75 my-4" type="submit">Sign in</button>
          </center>
        </form>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      GOOGLE_CLIENT_ID: "904073481875-bkm8mjsp2640plu8vntjeu1e662p6a4v.apps.googleusercontent.com",
      login: {
        email: "",
        password: "",
      },
      showLoginPass: false,
      isSignedIn: false,
    };
  },
  props: ["size", "label"],
  methods: {
    async loginGoogle() {
      // 2023
      // https://github.com/guruahn/vue-google-oauth2/issues/78
      //google auth
      //# https://console.cloud.google.com/apis/credentials?highlightClient=904073481875-bkm8mjsp2640plu8vntjeu1e662p6a4v.apps.googleusercontent.com&authuser=1&project=onlybots-399121&supportedpurview=project

      // const CLIENT_ID = "904073481875-bkm8mjsp2640plu8vntjeu1e662p6a4v.apps.googleusercontent.com"; //"1037525689970-e49k2579kis9vp4ffh5pm7gkoulkg684.apps.googleusercontent.com"; // unchanced from 2021
      // eslint-disable-next-line
      if (!google) alert("missing google lib");
      // eslint-disable-next-line
      // eslint-disable-next-line
      const client = google.accounts.oauth2.initCodeClient({
        client_id: this.GOOGLE_CLIENT_ID, // 'YOUR_GOOGLE_CLIENT_ID',
        scope: "https://www.googleapis.com/auth/userinfo.profile",
        ux_mode: "popup",
        callback: (response) => {
          if (response.code) {
            console.log(response.code); // gzh7hGyG33YfDrn17T9lfngzh7hGyG33YfDrn17T9lfngzh7hGyG33YfDrn17T9lfn
            console.log(response, "RESPONSE");
            //  alert('yay!');
            this.$store.main
              .dispatch("loginGoogle", response)
              .then(() => {
                //TODO: refresh current page? go to dashboard?
                this.$router.push("/?justLoggedGsi=1");
              })
              .catch((err) => {
                console.log(err);
                console.log("ERR AUTH ........");
                alert("Try again...");
                this.$router.push("/signin?wrongGoogleAuth=1");
              });
          }
        },
      });
      client.requestCode();
    },
    loadLibrary() {
      const scripts = ["https://accounts.google.com/gsi/client"]; //  'js/local.js'
      scripts.forEach((script) => {
        let tag = document.createElement("script");
        tag.setAttribute("src", script);
        tag.setAttribute("async", true); // opt?
        document.head.appendChild(tag);
      });
    },
    async loginUserPass() {
      try {
        this.$store.main
          .dispatch("loginPass", {
            email: this.login.email,
            password: this.login.password,
          })
          .then(() => {
            //alert('TODO: close login modal mutation...')
            this.$router.push("/?justLogged=1");
          })
          .catch((err) => {
            console.log(err);
            console.log("ERR AUTH ........");
            alert("wrong password...");
            this.$router.push("/login?wrongpass=1");
          });
        /*
          let response = await this.$http.post("/api/v2/auth/loginPass", this.login);
          let token = response.data.data.token;
          localStorage.setItem("userAuthToken", token);
          // navigate to a protected resource
          this.$router.push("/me"); */
      } catch (err) {
        console.log(err.response, err);
      }
    },

    async loginGoogleLEGACY() {
      // LEGACY SHIZZZZ

      console.log("this.$gAuth");
      console.log(this.$gAuth);
      const googleUser = await this.$gAuth.signIn(); // triggers error...
      //    alert('2')
      console.log(googleUser);
      // alert(googleUser);
      var auth = googleUser.getAuthResponse();
      this.$store.main
        .dispatch("loginGoogle", {
          // code: authCode,
          profile: googleUser.getBasicProfile(),
          auth: auth,
          id_token: auth.id_token,
          //googleUser:googleUser, //messedUp prop names. WTF google, be nice!
          //access_token:googleUser.tc.access_token
          //password: this.login.password
        })
        .then(() => {
          this.$router.push("/?justLoggedGoogle=1");
        })
        .catch((err) => {
          console.log(err);
          console.log("ERR AUTH ........");
          alert("Try again...");
          this.$router.push("/login?wrongGoogleAuth=1");
        });
    },
    logoutGoogle() {
      /*
        if (window.google && window.google.accounts && window.google.accounts.id) {
          window.google.accounts.id.disableAutoSelect();
        }*/
      //  optional
    },

    authenticate: function (provider) {
      //
    },
  },
  created() {
    this.loadLibrary();

    //let's also define the handler to RESPOND to these events.
    // eslint-disable-next-line
    globalThis.onGoogleGsiResponse = (response) => {
      console.log("onGoogleGsiResponse");
      console.log(response);
      this.$store.main
        .dispatch("loginGoogle", response)
        .then(() => {
          // TODO: refresh current page? go to dashboard?
          this.$router.push("/?justLoggedGoogleoneTap=1");
        })
        .catch((err) => {
          console.log(err);
          console.log("ERR AUTH .....48412...");
          alert("Try again...");
          this.$router.push("/login?wrongGoogleAuth=1");
        });
    };
  },
};
</script>
