<template>
  <div>
    <div class="section">
      <SignInForm :isSignIn="isSignIn" mode="page" />

      <jsonDebug :data="invite" label="invite data (if present)" />

      <div class="message is-warning" v-if="$store.main.state.userToken && invite">
        That's a preview of your invite page, you're already logged-in. <br />

        <br />
        Share this invite with a friend. <br />
        <a :href="inviteUrl" target="_blank">{{ inviteUrl }}</a
        ><br /><br /><br />

        <code style="padding: 20px; letter-spacing: 3px; color: #888; font-size: 20px">
          {{ inviteId }}
        </code>
        <br />
        <br />
        <!--code  -->

        <br />
        <br />

        <b-button @click="copyUrl" class="is-smalllLLLL is-dark">
          <i class="far fa-copy"></i>
          <span> Copy invite URL</span>
        </b-button>
      </div>

      <div class="container">
        <br />

        <div v-if="hasValidInvite">
          <h3 class="title is-1">You're in! 🙌</h3>
          <br />
          <p class="subtitle is-4">
            <strong> {{ invite.owner }}</strong> invited you to OnlyBots. <br />
            OnlyBots is the internet of AI. Create bots, talk with AIs and tap in the best content.
            <br />
            This invite key can only be used once 🔑
          </p>
        </div>

        <div v-else>
          <h3 class="title is-2">You need an invite 🔑</h3>
          <br />
          <p class="subtitle is-5">
            OnlyBots is an invite-only community of AI creators and chatbot enthusiasts.
            <br />
            Ask around on Reddit, Twitter or Discord for invites.
            <br /><br />
            We invite new members periodically.

            <router-link to="/">Add your email to the waitlist</router-link>.
          </p>
        </div>
        <br />

        <!-- 
        <div class="lg" v-if="$store.main.state.userToken && !$route.query.preview">
          <b-button @click="acceptInvite">Accept</b-button>
        </div> -->

        <div class="signup form" style="max-width: 600px">
          <!-- desired username  @ and password -->

          <b-field
            label="Username (for you, the human user)"
            class="field"
            :type="isUsernameAvailable ? 'is-success' : username.length > 0 ? 'is-danger' : ''"
          >
            <div>
              <b-input
                v-model="username"
                type="text"
                placeholder="Enter your desired username"
                message="You can have a message too"
                @input="checkUsernameAvailability"
              ></b-input>
              <span
                key="bas3"
                v-if="username.length > 0 && !isUsernameAvailable"
                class="icon is-small is-right has-text-danger"
              >
                <i class="fas fa-times"></i>
              </span>
              <span v-if="isUsernameAvailable" key="chk3" class="icon is-small is-right has-text-success">
                <i class="fas fa-check"></i>
              </span>
              <b-tooltip type="is-dark" position="is-right" multilinedNO label=" 5+ letters or numbers, dots allowed">
                <i class="fal fa-question-circle" aria-hidden="true"></i>
              </b-tooltip>
            </div>
          </b-field>

          <!-- signup form buefy -->
          <b-field label="Email" class="field">
            <b-input v-model="email" type="email" placeholder="Enter a valid email" required></b-input>
          </b-field>

          <b-field label="Password" class="field">
            <b-input v-model="password" type="password" placeholder="Enter a secure password" required></b-input>
          </b-field>

          <b-field label="Invite key" class="field">
            <b-input v-model="inviteId" type="text" placeholder="abc123" :disabled="hasValidInvite" required></b-input>
          </b-field>

          <b-button type="is-dark" @click="acceptInvite" :loading="posting" :disabled="!isUsernameAvailable || posting"
            >Create my account</b-button
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import SignInForm from "../components/auth/SignInForm.vue";

export default {
  components: {
    SignInForm,
  },
  data() {
    return {
      handleAvailable: null,
      email: "",
      posting: false,
      username: "",
      password: "",
      inviteId: "", // Add the invite ID to data
      invite: {},
      validatingInvite: true,
      isUsernameAvailable: false,
      hasValidInvite: false,
    };
  },
  metaInfo() {
    return {
      title: this.title,
      //  desc: this.user?.bio,
    };
  },
  computed: {
    title() {
      // if there's an invite, mention the owner "XXX invited you to OnlyBots"
      if (this.invite && this.invite.owner) return `${this.invite.owner} invited you!`;
      return "Signup";
    },
    inviteUrl() {
      return `${window.location.origin}/invite/${this.inviteId}`;
    },
    isLogged() {
      return this.$store.main.state.userToken;
    },
    isSignIn() {
      return this.$route.name === "signin";
    },
  },
  //on mounted fetch invite id dat from the api using the query secret.
  mounted() {
    // Get the invite ID from the route
    const inviteId = this.$route.params.inviteId;
    this.inviteId = inviteId;
    if (!inviteId) {
      this.validatingInvite = false;
      return false;
    }
    // Make a GET request to the server to get the invite data
    window.API.getInvite(inviteId)
      .then((res) => {
        // If the request is successful, set the email and invite ID
        this.invite = res;
        if (res && res.used === false) this.hasValidInvite = true;
        this.validatingInvite = false;
        //this.email = res.email;
        //this.inviteId = res.inviteId;
        //alert(this.invite.owner + this.$store.main.getters.handle);
        if (this.isLogged && this.invite.owner != this.$store.main.getters.handle) {
          this.$buefy.toast.open({
            message: "Already logged in", //+ err.message,
            type: "is-danger",
            position: "is-top",
            duration: 5000,
            queue: false,
          });
          this.$router.push("/");
        }
      })
      .catch((err) => {
        this.validatingInvite = false;
      });
  },

  methods: {
    copyUrl() {
      navigator.clipboard.writeText(this.inviteUrl);
      this.$buefy.toast.open({
        message: "Copied to clipboard",
        type: "is-dark",
      });
    },
    async checkUsernameAvailability() {
      console.log(23423);
      if (this.username.length === 0) {
        this.isUsernameAvailable = null;
        return;
      }
      this.isUsernameAvailable = false;
      try {
        //  const response = await fetch(`/auth/handle/${this.username}/available`);
        const data = await window.API.isHandleAvailable(this.username);
        console.log(data, "data");
        this.isUsernameAvailable = data;
      } catch (error) {
        console.error("Error checking username availability:", error);
        this.isUsernameAvailable = null;
      }
    },
    isHandleAvailable() {
      // Make a GET request to the server to check if the handle is available
      window.API.isHandleAvailable(this.username).then((res) => {
        // If the request is successful, set the email and invite ID
        this.handleAvailable = res || false;
        //this.email = res.email;
        //this.inviteId = res.inviteId;
      });
    },

    async acceptInvite() {
      if (this.$store.main.state.userToken) {
        //buefy toast: This is just a preview link, you can't accept the invite when you're already logged in
        this.$buefy.toast.open({
          message: "This is just a preview link, you can't accept the invite when you're already logged in",
          type: "is-dark",
          position: "is-top",
          duration: 9000,
          queue: false,
        });
        return false;
      }

      const data = {
        email: this.email,
        handle: this.username,
        password: this.password,
        inviteKey: this.inviteId, // Include the invite ID in the data payload
      };

      // Make a POST request to the server to accept the invite and create a new account
      window.API.signupPassInvite(data)
        .then((res) => {
          // If the request is successful, log the user in
          this.$store.main.commit("setUserToken", res.token);
          this.$store.main.commit("setHandle", res.handle);
          this.$store.main.commit("setAvatar", res.avatar); //nada
          this.$store.main.commit("setUserId", res.userId);
          // this.$store.main.commit("setUser", res.user);
          //   this.$router.push({ name: "Home" });
          this.$router.push("/welcome");
        })
        .catch((err) => {
          console.log(err, err.message);
          this.$buefy.toast.open({
            message: "Error: Ensure all informations are valid ", //+ err.message,
            type: "is-danger",
            position: "is-top",
            duration: 9000,
            queue: false,
          });
        });
    },
  },
};
</script>
